/**
 * 用户共享信息
*/
const user = {
    namespaced: true,
    state : {
        openid : '',
        token : '',
        userId : 0,
        first  : true,
        mobile  : '',
    },
    mutations: {
        setOpenId(state,openid){
            state.openid = openid;
            localStorage.openid = openid
        },
        setUserId(state,userId){
            state.userId = userId;
            localStorage.userId = userId //同步存储token至localStorage
        },
        setFirst(state, first){
            state.first = first;
        },
        setMobile(state, mobile){
            state.mobile = mobile
        }
    },
    getters : {
        //获取token方法
        getOpenId(state){
            if (!state.openid) {
                state.openid = localStorage.getItem('openid');
            }
            if(state.openid == null) return '';
            return state.openid

        },
        getUserId(state){
            if (!state.userId) {
                state.userId = localStorage.getItem('userId');
            }
            return state.userId

        },
        getMobile(state){
            if (!state.mobile) {
                state.mobile = localStorage.getItem('mobile');
            }
            return state.mobile

        },
    },
    actions: {

    }
};

export default  user;