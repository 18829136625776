/**
 * 选择地址信息
*/
const local = {
    namespaced: true,
    state : {
        provinceId : 0,
        cityId : 0,
        type : 0, //1直购车 2特价车 3个人中心
        local : '',
        url : '',
    },
    mutations: {
        setProvinceId(state, provinceId) {
            state.provinceId = provinceId;
            sessionStorage.provinceId = provinceId //同步存储provinceId至sessionStorage
        },
        setCityId(state,cityId){
            state.cityId = cityId;
            sessionStorage.cityId = cityId //同步存储cityId至sessionStorage
        },
        setLocal(state,local){
            state.local = local;
            sessionStorage.local = local //同步存储local至sessionStorage
        },
        setType(state,type){
            state.type = type;
            localStorage.localtype = type //同步存储local至sessionStorage
        },
        setUrl(state,url){
            state.url = url;
            localStorage.localtypeurl = url //同步存储local至sessionStorage
        },
    },
    getters : {
        getProvinceId(state) {
            if (!state.provinceId) {
                state.provinceId = sessionStorage.getItem('provinceId');
            }
            return state.provinceId
        },
        getCityId(state){
            if (!state.cityId) {
                state.cityId = sessionStorage.getItem('cityId');
            }
            return state.cityId
        },
        getLocal(state){
            if (!state.local) {
                state.local = sessionStorage.getItem('local');
            }
            return state.local
        },
        getType(state){
            if (!state.type) {
                state.type = localStorage.getItem('localtype');
            }
            return state.type
        },
        getUrl(state){
            if (!state.url) {
                state.url = localStorage.getItem('localtypeurl');
            }
            return state.url
        },
    },
    actions: {

    }
};

export default  local;