/**
 * 订单共享信息
*/
const order = {
    namespaced: true,
    state : {
        showSsss : 0,
        ssssId: 0,
        provinceId : 0,
        cityId : 0,
        colorId : 0,
        colorVal : '',
        typeId: 0,
        markId: 0,
        carVals : '- - -',
        carPrice : '-',
        guidePrice : '-',
        couponId : 0,    //优惠券id
        userCouponId : 0,    //持有优惠券id
    },
    mutations: {
        //存储token方法
        //设置token等于外部传递进来的值
        setShowSsss(state, showSsss){
            state.showSsss = showSsss;
        },
        setSsssId(state, ssssId){
            state.ssssId = ssssId;
        },
        setProvinceId(state, provinceId) {
            state.provinceId = provinceId;
        },
        setCityId(state, cityId){
            state.cityId = cityId;
        },
        setColorId(state, colorId){
            state.colorId = colorId;
        },
        setColorVal(state, colorVal){
            state.colorVal = colorVal;
        },
        setTypeId(state, typeId){
            state.typeId = typeId;
        },
        setMarkId(state, markId){
            state.markId = markId;
        },
        setCarVals(state, carVals){
            state.carVals = carVals;
        },
        setCarPrice(state, carPrice){
            state.carPrice = carPrice;
        },
        setGuidePrice(state, guidePrice){
            state.guidePrice = guidePrice;
        },
        setCouponId(state, couponId){
            state.couponId = couponId;
        },
        setUserCouponId(state, userCouponId){
            state.userCouponId = userCouponId;
        },
    },
    getters : {
        //获取token方法
        //判断是否有token,如果没有重新赋值，返回给state的token
    },
    actions: {

    }
};

export default  order;