<template>
    <div id="act240224-history">
        <!--    页面主题-->
        <div class="act-container">
            <div class="bg-img">
                <img class="act-logo" src="/images/act/20240224/act-bg-car.png"/>
            </div>
            <div class="order_list_container">
                <div class="order-list">
                    <div class="order-item" v-for="(item ,index) in list" :key="index"  @click="navToOrderDetail(item.id)">
                        <div class="order-address-point order-start-point">
                            <div class="order-address">
                                <div class="point-info">{{item.origin}}</div><div class="time">（{{item.ustime}}）</div>
                                <div class="show-address-info-btn" @click.stop="showAddress(item.origin)">详情</div>
                                <div class="address-ball ball-green"></div>
                            </div>
                        </div>
                        <div class="order-status">{{item.state}}</div>
                        <div class="order-address-point order-end-point">
                            <div class="order-address">
                                <div class="point-info">{{item.end}}</div>
                                <div class="show-address-info-btn"  @click.stop="showAddress(item.end)">详情</div>
                                <div class="address-ball ball-red"></div>
                            </div>
                        </div>
                        <div class="item-bottom-border"></div>
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast, DatetimePicker,Checkbox} from 'vant';
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(DatetimePicker).use(Checkbox);
    export default {
        name: 'act221226',
        data() {
            return {
                loginShow: true,
                loadShow: false,
                subFormChecked : false,
                btnDisable : false,

                list: [],
                loading: false,
                finished: false,
                isLoading : false,
                pageIndex : 1,
                pageSize : 7
            }
        },
        created: function () {
            let userId = this.$store.getters['user/getUserId'];
            if(userId == 0){
                window.location.replace('/audi_member');
            }
            let mobile = this.$store.getters['user/getMobile'];
            if(mobile == 0 || mobile == null){
                window.location.replace('/audi_member');
            }

            this.loadOrdList(this.pageIndex).then((data) => {
                this.list = data.list;
                console.log(data);
                this.pageIndex = data.page;
            });
        },
        methods: {
            onLoad() {
                // 异步更新数据更多数据
                if(!this.finished && !this.isLoading){
                    setTimeout(() => {
                        let pageIndex = this.pageIndex;
                        pageIndex++;
                        this.loadOrdList(pageIndex).then((data) => {
                            let list = this.list;
                            this.list = list.concat(data.list);
                            this.pageIndex = data.page;
                        });
                    }, 500);
                }
            },
            //前往订单详情
            navToOrderDetail: function(orderId){
                this.$router.push({
                    path: 'audi_order_detail',
                    query: {
                        orderId : orderId //1: 接机，2：送机
                    },
                })
            },
            //载入订单列表
            loadOrdList ($pageIndex){
                this.loadShow=true;
                this.isLoading = true;
                return new Promise((resolve) => {
                    let data = {
                        'uid' : this.$store.getters['user/getUserId'],
                        'openid' : this.$store.getters['user/getOpenId'],
                        'mobile' : 18917501421,
                        'pageIndex' : $pageIndex,
                        'pageSize' : this.pageSize,
                    };
                    this.$post('/user/getAudiOrderList', data).then((res) => {
                        if(res.code == 1000){
                            console.log(res.data);
                            let len = res.data.list.length;
                            if(len > 0){
                                for (var i = 0; i < len ; i++){
                                    //处理数据
                                    // res.data.list[i]['guidePirce'] = this.numberFormat(res.data.list[i]['guidePirce']);
                                }
                            }
                            resolve(res.data);
                            if(res.data['pageCount'] < res.data['pageSize']){
                                this.finished = true;
                            }else{
                                this.finished = false;
                            }
                        }
                        this.loadShow = false;
                        this.isLoading = false;
                    });
                })
            },
            showAddress: function(info){
                Toast(info);
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
        }
    }
</script>

<style>
    body{
        max-width: 766px;
        margin: auto;
        background: linear-gradient( to right, #fafafc 0%, #e3e4e6 100%);
    }
    #act240224-history{
        position: fixed;
        height: 100%;
        width: 100%;
        overflow: scroll;
    }
    #act240224-history .act-container{
        height: 100%;
        padding-bottom: 140px;
    }
    #act240224-history .act-container .bg-img{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    #act240224-history .act-container .bg-img img{
        width: 100%;
    }

    #act240224-history .order_list_container{
        width: 100%;
        height: 100%;
        overflow: scroll;
        padding-bottom: 40px;
    }

    #act240224-history .order_list_container .order-list{
        width: 100%;
    }
    #act240224-history .order_list_container .order-list .order-item{
        padding: 16px 24px;
        padding-bottom: none;
        position: relative;
        color: #424242;
    }
    #act240224-history .order_list_container .order-item .item-bottom-border{
        height: 1px;
        width: 100%;
        background: linear-gradient(270deg, rgba(255, 255, 255, 1), rgba(228, 229, 231, 1));
        margin-top: 16px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    #act240224-history .order_list_container .order-item .order-address{
        text-align: left;
        font-size: 12px;
        line-height: 14px;
        margin: 8px 0;
        position: relative;
        white-space: nowrap;
        display: flex;
    }
    #act240224-history .order_list_container .order-item .order-address .point-info{
        width: 80px;
        display: inline-block;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    #act240224-history .order_list_container .order-item .order-address .time{
        line-height: 16px;

    }
    #act240224-history .order_list_container .order-item .order-status{
        width: 100%;
        height: 34px;
        line-height: 34px;
        padding: 0 26px;
        border-top: 1px solid #D4D4D6;
        border-bottom: 1px solid #D4D4D6;
        text-align: right;
        font-size: 12px;
    }
    #act240224-history .order_list_container .order-item .show-address-info-btn{
        background:#BCA890;
        color: #fff;
        padding: 0 10px;
        border-radius: 7px 7px 7px 7px;
        font-size: 10px;
        height: 14px;
        font-weight: normal;
        width: 40px;
        margin: auto 0;

    }
    #act240224-history .order_list_container .order-item .address-ball{
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50px;
    }
    #act240224-history .order_list_container .order-item .ball-green{
        background: #95E1D3;
        box-shadow: 0px 4px 6px 0px rgba(79,156,142,0.4);
        bottom: -14px;
    }
    #act240224-history .order_list_container .order-item .ball-red{
        background: #F38181;
        box-shadow: 0px 4px 6px 0px rgba(243,129,129,0.5);
        top: -14px;
    }
</style>
