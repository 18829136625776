<template>
    <div id="act240224_o_detail">
        <!--    页面主题-->
        <div class="act-container">
            <div class="bg-img">
                <img class="act-logo" src="/images/act/20240224/act-bg-car.png"/>
            </div>
            <div class="show-info-box alert-box border-container">
                <div class="info-container border-line">
                    <div class="address-box">
                        <div class="address-border-line">
                            <div class="address-left-line"></div>
                            <div class="address-point point-start">(起){{detail.origin}}</div>
                            <div class="address-point point-middel"></div>
                            <div class="address-point point-end">(终){{detail.end}}</div>
                        </div>
                    </div>
                    <div class="order-info">
                        <div class="info-title">订单状态</div>
                        <div class="order_status">{{detail.order_state}}</div>
                    </div>
                    <div class="order-info">
                        <div class="info-title">乘客信息</div>
                        <div class="info-item">
                            <div class="item-title">姓名</div>
                            <div class="item-content">{{detail.passenger_name}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">手机号</div>
                            <div class="item-content">{{detail.passenger_phone}}</div>
                        </div>
                    </div>
                    <div class="order-info" v-if="detail.order_status >= 3">
                        <div class="info-title">车辆信息</div>

                        <div class="info-item">
                            <div class="item-title">司机</div>
                            <div class="item-content">{{detail.chauffeur_name}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">手机号</div>
                            <div class="item-content">{{detail.chauffeur_phone}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">车牌号</div>
                            <div class="item-content">{{detail.car_number}}</div>
                        </div>
                    </div>
                    <div class="order-info">
                        <div class="info-title">订单信息</div>

                        <div class="info-item">
                            <div class="item-title">用车时间</div>
                            <div class="item-content">{{detail.ustime}}</div>
                        </div>

                        <div class="info-item">
                            <div class="item-title">订单号</div>
                            <div class="item-content">{{detail.order_number}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">车系</div>
                            <div class="item-content">{{detail.car_series}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">航班号</div>
                            <div class="item-content">{{detail.flight_number}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-title">备注</div>
                            <div class="item-content">{{detail.remarks}}</div>
                        </div>
                    </div>
                </div>
                <div class="pay-btn-list" v-if="detail.order_status == 1">
<!--                    待支付-->
                    <div class="btn-item btn-cancel" @click="cancelOrder" >取消</div>
                    <div class="btn-item btn-pay" @click="alertPayInfo">确认支付</div>
                </div>
                <div class="btn-list">
                    <div class="btn-item btn-history" @click="cancelOrder"  v-if="detail.order_status == 2">
                        <img class="btn-icon" src="/images/act/20240224/act-icon-history.png"/>
                        取消订单
                    </div>
                    <div class="btn-item btn-submit" @click="callService" v-if="detail.order_status != 1">
                        <img class="btn-icon" src="/images/act/20240224/act-icon-custom.png"/>
                        客服
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

        <van-overlay :show="payAlertShow"  class="overlay-div">
            <div class="payalert-container">
                <div class="payalert-box">
                    <div class="payalert-item payAlert-1">
                        <div class="pay-content">本次行程需支付<span class="num">{{payInfo.price}}</span>元</div>
                    </div>
                    <div class="payalert-item payAlert-2">
                        <div class="pay-2-item">订单公里数<div class="pay-2-num">{{payInfo.mileage}}公里</div></div>
                        <div class="pay-2-item">基础报价<div class="pay-2-num">{{payInfo.cipprice}}元</div></div>
                        <div class="pay-2-item">超公里费<div class="pay-2-num">{{payInfo.overstep_fee}}元</div></div>
                        <div class="pay-2-item pay-2-item-mini">超出50km的费用，按10元/km计算</div>
                        <div class="pay-2-item">{{payInfo.coupon}}</div>
                        <div class="pay-2-item pay-2-item-mini">减免{{payInfo.pre_fee}}元</div>
                    </div>
                    <div class="payalert-item payAlert-3">
                        <div class="pay-btn" @click="toPay">立即支付</div>
                    </div>
                </div>
            </div>
        </van-overlay>

    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Toast, Dialog} from 'vant';
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Toast).use(Dialog);
    export default {
        name: 'act221226',
        data() {
            return {
                orderId : 0,
                loginShow: true,
                loadShow: false,
                subFormChecked : false,
                btnDisable : false,
                buttonLock : false,
                detail: {
                    origin: '-',
                    end : '-',
                    passenger_name: '-',
                    passenger_phone: '-',
                    order_state: '-',
                    ustime: '-',
                    order_number: '-',
                    chauffeur_name : '-',
                    chauffeur_phone : '-',
                    car_number : '-',
                    remarks : '-',
                    car_series: '-',
                    order_status : 0
                },
                payInfo : {
                    cipprice: '-', //基础报价
                    overstep_fee: '-', //超公里费
                    pre_fee: '-', //减免金额
                    price: '-', //折后价
                    mileage: '-', //公里数
                    coupon: '-', //券名
                },
                payAlertShow: false,
            }
        },
        created: function () {
            // this.loadShow=true;
            let query = this.$route.query;
            this.orderId = query.orderId == null || query.orderId == 0 ? 0 : query.orderId;
            if(this.orderId == 0 || this.orderId == null){
                Toast('订单信息不存在');
                setTimeout(
                    function(){
                        window.location.replace('/audi_member');
                    }
                    ,2000)
            }
            //用户信息不存在
            let userId = this.$store.getters['user/getUserId'];
            if(userId == 0){
                window.location.replace('/audi_member');
            }

            this.loadOrderInfo();
        },
        methods: {
            loadOrderInfo: function(){
                this.loadShow = true    ;
                this.payAlertShow = false;
                let orderId = this.orderId;
                let data = {
                    'orderid' : orderId,
                    'openid' : this.$store.getters['user/getOpenId'],
                    'uid' : this.$store.getters['user/getUserId'],
                };

                this.$post('/user/getAudiOrderDetail', data).then((res) => {

                    if(res.code == 1000){
                        let data = res.data;
                        console.log(data);
                        if(data.flight_number == '') data.flight_number = '-';
                        this.detail = data;

                        this.payInfo = {
                            cipprice: data.cipprice, //基础报价
                            overstep_fee: data.overstep_fee, //超公里费
                            pre_fee: data.pre_fee, //减免金额
                            price: data.price, //折后价
                            mileage: data.mileage, //公里数
                            coupon: data.coupon, //折扣券
                        };
                        this.loadShow = false;
                    }else{
                        Dialog.alert({
                            message: res.msg,
                        }).then(() => {
                            // on close 跳转到首页
                            window.location.href = '/audi_member';
                        });
                    }
                });
            },
            //弹出支付信息
            alertPayInfo: function(){
                this.payAlertShow = true;
            },
            //取消订单
            cancelOrder: function(){
                var that = this;
                Dialog.confirm({
                    title: '确认取消订单',
                })
                    .then(() => {
                        // on confirm
                        let orderId = this.orderId;
                        let data = {
                            'orderid' : orderId,
                            'openid' : this.$store.getters['user/getOpenId'],
                            'uid' : this.$store.getters['user/getUserId'],
                        };

                        this.$post('/user/audiCancelOrder', data).then((res) => {

                            if(res.code == 1000){
                                this.loadShow = false;
                                Dialog.alert({
                                    message: '订单取消成功',
                                }).then(() => {
                                    that.loadOrderInfo();
                                });
                            }else{
                                Dialog.alert({
                                    message: res.msg,
                                }).then(() => {
                                    // on close 跳转到首页
                                    window.location.href = '/audi_member';
                                });
                            }
                        });
                    })
                    .catch(() => {
                        // on cancel
                    });

            },
            //前往订单历史
            navToHistory: function(){
                this.$router.push({
                    path: '/audi_history',
                    query: {},
                })
            },
            //支付启动
            toPay: function(){
                var that = this;
                let orderId = this.orderId;
                if(this.buttonLock){
                   Toast('操作频繁，请稍后重试')
                }
                this.buttonLock = true; //上锁不让操作
                let data = {
                    'orderid' : orderId,
                    'openid' : this.$store.getters['user/getOpenId'],
                    'uid' : this.$store.getters['user/getUserId'],
                };

                this.$post('/user/perPayOrder', data).then((res) => {
                    if(res.code == 1000){
                        let data = res.data;

                        wx.ready(function(){
                            wx.chooseWXPay({
                                timestamp: data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                                nonceStr: data.nonceStr, // 支付签名随机串，不长于 32
                                package: data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                                signType: data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                                paySign: data.sign, // 支付签名
                                success: function (res) {
                                    // //跳转到支付成功页面有这个页面
                                    // $this.$router.push({
                                    //     path: "/success_page",
                                    //     name:"success_page"
                                    // })
                                    // console.log(res);
                                    console.log('支付成功');
                                    Toast('支付成功');
                                    setTimeout(that.loadOrderInfo(), 200);//信息刷新
                                    that.buttonLock = false;
                                },
                                complete: function (res) {
                                    that.buttonLock = false;
                                },
                                cancel: function (res) {//提示引用的是mint-UI里toast
                                    console.log('已取消支付');
                                    that.buttonLock = false;
                                },
                                fail: function (res) {
                                    console.log('支付失败，请重试');
                                    that.buttonLock = false;
                                }
                            })
                        })
                    }
                });
            },
            //拨打客服
            callService: function(){
                window.location.href = 'tel://50703315'
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
        }
    }
</script>

<style>
    body{
        max-width: 766px;
        margin: auto;
        background: linear-gradient( to right, #fafafc 0%, #e3e4e6 100%);
    }
    #act240224_o_detail{
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
    #act240224_o_detail .act-container{
        position: relative;
        height: 72%;
    }
    #act240224_o_detail .act-container .bg-img{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    #act240224_o_detail .act-container .bg-img img{
        width: 100%;
    }
    #act240224_o_detail .show-info-box{
        padding: 16px 24px;
        height: 100%;
    }
    #act240224_o_detail .border-line{
        background-image:url('/images/act/20240224/act-bg-border.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;
        padding: 16px;
        box-sizing: border-box;
        height: 100%;
        overflow: scroll;
    }

    #act240224_o_detail .address-box{
        width: 100%;
        color: #424242;
        font-size: 14px;
        line-height: 21px;
        text-align: left;
        padding: 0 34px;
        position: relative;
    }
    #act240224_o_detail .address-box .address-left-line{
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        width: 8px;
        background: linear-gradient( 180deg, #BCA890 0%, rgba(188,168,144,0) 100%);
        border-radius: 4px 4px 4px 4px;
    }
    #act240224_o_detail .address-box .address-point{
        margin: 8px 0;
    }
    #act240224_o_detail .address-box .point-middel{
        height: 20px;
    }
    #act240224_o_detail .order-info{
        position: relative;
    }
    #act240224_o_detail .order-info .info-title{
        color: #424242;
        font-size: 14px;
        text-align: left;
        padding-left: 12px;
        border-left: 8px solid #BCA890;
        height: 21px;
        line-height: 21px;
        margin-top: 21px;
    }
    #act240224_o_detail .order-info .order_status{
        position: absolute;
        height: 24px;
        line-height: 24px;
        border-radius: 14px 14px 14px 14px;
        border: 1px solid #BCA890;
        width: 76px;
        font-size: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 100px;
    }
    #act240224_o_detail .order-info .info-item{
       position: relative;
        text-align: left;
        padding-left: 102px;
        font-size: 14px;
        min-height: 24px;
    }
    #act240224_o_detail .order-info .info-item .item-title{
        text-align: right;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 102px;;
        margin: auto;
        padding-right: 16px;
        line-height: 32px;
    }
    #act240224_o_detail .order-info .info-item .item-content{
        border-bottom: 1px solid #BCA890;
        padding-right: 6px;
        line-height: 32px;
        min-height: 32px;
    }

    #act240224_o_detail .show-info-box .btn-list{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 24px;
        padding: 0 20px;
    }
    #act240224_o_detail .show-info-box .btn-list .btn-item{
        display: inline;
        color: #8A8A8C;
        border: 1px solid #8A8A8C;
        border-radius: 16px;
        padding: 8px 16px;
        font-size: 12px;
        padding-left: 41px;
        position: relative;
        line-height: 18px;
    }
    #act240224_o_detail .show-info-box .btn-icon{
        width: 18px;
        height: 18px;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    #act240224_o_detail .pay-btn-list{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-bottom: 20px;
        display: flex;
        font-size: 14px;
        background: #fff;
    }
    #act240224_o_detail .pay-btn-list .btn-item{
        line-height: 48px;
        height: 48px;
        background: #fff;
        color: #000;
    }
    #act240224_o_detail .pay-btn-list .btn-cancel{
        width: 40%;
    }
    #act240224_o_detail .pay-btn-list .btn-pay{
        background: #BCA890;
        color: #fff;
        width: 60%;
    }

    #act240224_o_detail .payalert-container{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 10px;
        box-shadow: 0px -4px 16px 0px rgba(0,0,0,0.2);
        border-radius: 15px 15px 0px 0px;
        background: linear-gradient( 102deg, #FAFAFC 0%, #E3E4E6 100%);
    }
    #act240224_o_detail .payalert-container .payAlert-1{
        padding-bottom: 16px;
        border-bottom: 1px solid #E4E4E4;
        line-height: 27px;
    }
    #act240224_o_detail .payalert-container .pay-content{
        padding-bottom: 16px;
    }
    #act240224_o_detail .payalert-container .payalert-item{
        padding: 0 24px;
        text-align: left;
    }
    #act240224_o_detail .payalert-container .pay-content .num{
        color: #F38181;
        font-size: 20px;
        padding: 0 4px;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-2{
        border-bottom: 1px solid #E4E4E4;
        padding: 16px ;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-2 .pay-2-item{
        position: relative;
        padding: 4px 0;
        font-size: 14px;
        line-height: 20px;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-2 .pay-2-num{
       position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-2 .pay-2-item-mini{
        color: #B9B9B9;
        font-size: 10px;
        padding-top: 0;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-3{
        padding: 32px 104px;
        padding-bottom: 72px;
    }
    #act240224_o_detail .payalert-container .payalert-box .payAlert-3 .pay-btn{
        width: 100%;
        text-align: center;
        background: #F38181;
        border-radius: 16px 16px 16px 16px;
        color: #fff;
        height: 32px;
        line-height: 32px;
    }

    #act240224_o_detail .hidden {
        display: none;
    }
</style>
