<template>
    <div id="act240224">
        <!--    页面主题-->
        <div class="act-container">
            <div class="bg-img">
                <img class="act-logo" src="/images/act/20240224/act-bg-car.png"/>
            </div>
            <div class="title-box">
                <img class="act-logo" src="/images/act/20240224/parklanelog.png"/>
                <div class="act-title">
                    威信出行多城市接送机礼遇服务
                </div>
            </div>
            <div v-if="showIndex == 1" class="show-info-box alert-box border-container">
                <div class="info-container border-line">
                    <div class="info-box">
                        <div class="info-title">奥迪权益流程</div>
                        <div class="info-content">
                            1.客户从奥迪方获取 9折或5折优惠券<br/>
                            2.使用微信扫描二维码访问活动页面网站<br/>
                            3.免费注册登陆后成为威信出行品牌会员<br/>
                            4.预定输入客户用车信息+备注(包括活动券码)<br/>
                            5.客户付款-微信支付<br/>
                            6.支付成功，转入订单详情(涵盖订单取消功能)<br/>
                            7.订单取消规则:<br/>
                            &emsp; (1)乘客无责标准:订单确认后，提前于预定服务时间五小时免责取消订单，支付款原路退回<br/>
                            &emsp; (2)乘客爽约标准:<br/>
                            &emsp; 按照取消时间梯度收取爽约费用<br/>
                            &emsp; &emsp; 距离出发前 5 小时内 1 小时外取消，收取下单预付价格3%的调度费；<br/>
                            &emsp; &emsp; 距离出发前 1 小时内取消，收取下单预付价格5%的调度费；<br/>
                            &emsp; &emsp; 距离出发前 0.5 小时内取消，收取下单预付价格 10%的调度费；<br/>
                            &emsp; &emsp; 超过预约出发时间取消，收取下单预付价格的 15%的调度费，最高收取50元。
                        </div>
                    </div>
                </div>
                <div class="mini-content">*此次活动的最终解释权归奥迪和活动品牌方所有</div>
                <div class="btn-list">
                    <div class="btn-item btn-history" @click="navToHistory">
                        <img class="btn-icon" src="/images/act/20240224/act-icon-history-2.png"/>
                        历史订单
                    </div>
                    <div class="btn-item btn-submit" @click="alertNavOrderSub">
                        <img class="btn-icon" src="/images/act/20240224/act-icon-crown-2.png"/>
                        使用权益
                    </div>
                </div>
            </div>
            <div v-else-if="showIndex == 2" class="login-box alert-box border-container">
                <div class="border-line">
                    <div class="box-title">登录</div>
                    <form class="submit-container" @submit.prevent="logFormSub">
                        <div class="form-input">
                            <input placeholder="请输入手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入密码" v-model="logFormObj.passwd" @blur="clearTop" maxlength="30" type="password"/>
                        </div>
                        <div class="nav-list">
                            <div class="nav-item forget-nav" @click="changeShowIndex(4)">忘记密码</div>
                            <div class="nav-item register-nav" @click="changeShowIndex(3)">注册账号</div>
                        </div>
                        <div class="btn-list">
                            <van-button class="submit-btn  gold-btn" round type="info" size="large" native-type="submit">
                                <span>登陆</span>
                            </van-button>
                        </div>
                    </form>
                </div>
                <div class="mini-content">*此次活动的最终解释权归奥迪和活动品牌方所有</div>

            </div>
            <div v-else-if="showIndex == 3" class="register-box alert-box border-container">
                <div class="border-line">
                    <div class="box-title">注册</div>
                    <form class="submit-container" @submit.prevent="regFormSub">
                        <div class="form-input mobile-container">
                            <input placeholder="请输入用户名" v-model="registerFromObj.name" maxlength="11" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入手机号" v-model="registerFromObj.mobile" maxlength="11" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入验证码" v-model="registerFromObj.token" @blur="clearTop" maxlength="6"/>
                            <div class="mobile-btn five-btn" @click="getMobileRegisterToken()">{{tokenBtnMsg2}}</div>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入密码" v-model="registerFromObj.passwd" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请再次输入密码" v-model="registerFromObj.passwd2" @blur="clearTop" maxlength="30"/>
                        </div>

                        <div class="btn-list">
                            <van-button class="cancel-btn" round type="info" size="large" @click="changeShowIndex(2)">
                                <span >取消</span>
                            </van-button>

                            <van-button class="submit-btn  gold-btn" round type="info" size="large" native-type="submit">
                                <span>确认</span>
                            </van-button>
                        </div>
                    </form>
                </div>
                <div class="mini-content">*此次活动的最终解释权归奥迪和活动品牌方所有</div>

            </div>
            <div v-else-if="showIndex == 4" class="foreget-box alert-box border-container">
                <div class="border-line">
                    <div class="box-title">忘记密码</div>
                    <form class="submit-container" @submit.prevent="forgetFormSub">
                        <div class="form-input">
                            <input placeholder="请输入手机号" v-model="registerFromObj.mobile" maxlength="11" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入验证码" v-model="registerFromObj.token" @blur="clearTop" maxlength="6"/>
                            <div class="mobile-btn five-btn" @click="getMobileForgetToken()">{{tokenBtnMsg1}}</div>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入密码" v-model="registerFromObj.passwd" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请再次输入密码" v-model="registerFromObj.passwd2" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="btn-list">
                            <van-button class="cancel-btn" round type="info" size="large" @click="changeShowIndex(2)">
                                <span >取消</span>
                            </van-button>
                            <van-button class="submit-btn gold-btn" round type="info" size="large" native-type="submit">
                                <span>确认</span>
                            </van-button>
                        </div>
                    </form>
                </div>
                <div class="mini-content">*此次活动的最终解释权归奥迪和活动品牌方所有</div>

            </div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

        <van-overlay :show="userTypeShow"  class="overlay-div" class-name="userTypeAlert">
            <div class="chosseUserType-container">
                <img class="x-icon" src="/images/act/20240224/act-icon-x.png" @click="closeNavOrderSub"/>
                <div class="user-type-title">用车类型</div>
                <div class="user-type-chosse">
                    <div class="user-type-choose-item" @click="navToOrderSub(1)">接机</div>
                    <div class="user-type-choose-item" @click="navToOrderSub(2)">送机</div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast, DatetimePicker,Checkbox} from 'vant';
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(DatetimePicker).use(Checkbox);
    export default {
        name: 'act240224',
        data() {
            return {
                showIndex: 2,   //1：首页 2：登录 3：注册 4:忘记密码
                loginShow: true,
                loadShow: false,
                subFormChecked : false,
                btnDisable : false,
                btnToken1Disable : false,
                btnToken2Disable : false,
                registerFromObj: {
                    name: '',
                    mobile: '',
                    token: '',
                    passwd: '',
                    passwd2: ''
                },
                logFormObj: {
                    mobile: '',
                    passwd: ''
                },
                tokenBtnMsg1 : '获取验证码',
                tokenBtnMsg2 : '获取验证码',
                deadline : 60,
                userTypeShow: false
            }
        },
        created: function () {
            this.currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            this.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

            let openId = this.$store.getters['user/getOpenId'];
            this.getInfo(openId);

            let userId = this.$store.getters['user/getUserId'];
            if(userId != 0){
                this.showIndex = 1;
            }
        },
        methods: {
            //前往订单历史
            navToHistory: function(){
                this.$router.push({
                    path: 'audi_history',
                    query: {},
                })
            },
            alertNavOrderSub: function(){
                this.userTypeShow = true;
            },
            closeNavOrderSub: function(){
                this.userTypeShow = false;
            },
            //前往订单历史
            navToOrderSub: function(type){
                this.$router.push({
                    path: 'audi_order',
                    query: {
                        type : type //1: 接机，2：送机
                    },
                })
            },
            //切换showIndex
            changeShowIndex: function(index){
                this.showIndex = index;
            },
            getInfo: function (openId){
                var that = this;
                var url = '/user/audiUserInfo';
                this.loadShow =true;
                this.$post(url, {
                    openid : openId,
                }).then((res) => {
                    if(res.code == 1000){
                        var data = res.data;
                        var userid = data.userid == null ? 0 : data.userid;
                        var mobile = data.mobile == null ? 0 : data.mobile;
                        this.$store.commit('user/setUserId',userid);
                        this.$store.commit('user/setMobile',mobile);
                        if(userid != 0){
                            that.showIndex = 1;
                        }

                        this.loadShow =false;
                    }else{
                        this.loadShow =false;
                    }
                }).catch(e => {
                    Toast('系统异常');
                });
            },
            /**
             *  登陆功能提交
             * @returns {boolean}
             */
            logFormSub: function () {
                var that = this;
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;

                var url = '/user/audiLogin';
                //请求绑定
                let mobile = this.logFormObj.mobile;
                let passwd = this.logFormObj.passwd;
                if(this.isPhone(mobile)){
                    if(passwd == ''){
                        Toast('请输入密码');
                        this.btnDisable = false;
                        return false;
                    }
                    //m
                    passwd = this.$md5(passwd);
                    let openId = this.$store.getters['user/getOpenId'];
                    this.$post(url, {
                        mobile : mobile,
                        password : passwd,
                        openid : openId,
                    }).then((res) => {
                        if(res.code == 1000){
                            //都有，那么请你去index主页候命
                            var data = res.data;
                            that.showIndex = 1;
                            this.$store.commit('user/setUserId', data.id);
                            this.$store.commit('user/setMobile', data.mobile);
                            // let userId = store.getters['user/getUserId'];
                            this.btnDisable = false;
                        }else{
                            Toast(res.msg);
                            this.btnDisable = false;
                        }
                    }).catch(e => {

                        this.btnDisable = false;
                    });
                }else{
                    Toast('请输入正确的手机号');
                    this.btnDisable = false;
                }
                return false;
            },
            /**
             *  注册功能提交
             * @returns {boolean}
             */
            regFormSub: function () {
                var that = this;
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;

                //请求绑定
                let name = this.registerFromObj.name;
                let mobile = this.registerFromObj.mobile;
                let token = this.registerFromObj.token;
                let passwd = this.registerFromObj.passwd;
                let passwd2 = this.registerFromObj.passwd2;
                if(name == ''){
                    Toast('请输入用户名');
                    this.btnDisable = false;
                    return false;
                }
                if(this.isPhone(mobile)){
                    if(passwd == ''){
                        Toast('请输入密码');
                        this.btnDisable = false;
                        return false;
                    }
                    if(passwd != passwd2){
                        Toast('请输入两次密码不一致，请确认');
                        this.btnDisable = false;
                        return false;
                    }
                    //m
                    passwd = this.$md5(passwd);
                    var url = '/user/audiRegister';
                    this.$post(url, {
                        username: name,
                        mobile : mobile,
                        password : passwd,
                        code : token
                    }).then((res) => {
                        if(res.code == 1000){
                            //都有，那么请你去index主页候命
                            that.showIndex = 2;
                            Toast('注册成功，请登录');
                            this.btnDisable = false;
                        }else{
                            Toast(res.msg);
                            this.btnDisable = false;
                        }
                    }).catch(e => {

                        this.btnDisable = false;
                    });
                }else{
                    Toast('请输入正确的手机号');
                    this.btnDisable = false;
                }
                return false;
            },
            /**
             *  注册功能提交
             * @returns {boolean}
             */
            forgetFormSub: function () {
                var that = this;
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;

                //请求绑定
                let mobile = this.registerFromObj.mobile;
                let token = this.registerFromObj.token;
                let passwd = this.registerFromObj.passwd;
                let passwd2 = this.registerFromObj.passwd2;
                if(this.isPhone(mobile)){
                    if(passwd == ''){
                        Toast('请输入密码');
                        this.btnDisable = false;
                        return false;
                    }
                    if(passwd != passwd2){
                        Toast('请输入两次密码不一致，请确认');
                        this.btnDisable = false;
                        return false;
                    }
                    //m
                    passwd = this.$md5(passwd);
                    var url = '/user/forgotPassword';
                    this.$post(url, {
                        mobile : mobile,
                        password : passwd,
                        code : token
                    }).then((res) => {
                        if(res.code == 1000){
                            //都有，那么请你去index主页候命
                            that.showIndex = 2;
                            Toast('修改成功，请登录');
                            this.btnDisable = false;
                        }else{
                            Toast(res.msg);
                            this.btnDisable = false;
                        }
                    }).catch(e => {

                        this.btnDisable = false;
                    });
                }else{
                    Toast('请输入正确的手机号');
                    this.btnDisable = false;
                }
                return false;
            },
            /**
             * 获取忘记手机验证码
             */
            getMobileForgetToken: function () {
                if(this.btnToken1Disable){
                    return false;
                }
                this.btnToken1Disable = true;
                let mobile = this.registerFromObj.mobile;
                if(this.isPhone(mobile)){
                    this.$post('/user/audiForgotSms', {
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 1000){
                            //循环倒计时
                            this.tokenBtnMsg1 = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg1 = '获取验证码';
                                    this.deadline = 60;
                                    this.btnToken1Disable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg1 = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnToken1Disable = false;
                            Toast(res.msg);
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnToken1Disable = false;
                        Toast('网路异常请稍后重试');
                    });
                }else{
                    Toast('请输入正确的手机号');
                    this.btnToken1Disable = false;
                }
                return false
            },
            /**
             * 获取注册手机验证码
             */
            getMobileRegisterToken: function () {
                if(this.btnToken2Disable){
                    return false;
                }
                this.btnToken2Disable = true;
                let mobile = this.registerFromObj.mobile;
                if(this.isPhone(mobile)){
                    this.$post('/user/audiSendSms', {
                        mobile :  mobile,
                    }).then((res) => {
                        console.log(res);
                        if(res.code == 1000){
                            //循环倒计时
                            this.tokenBtnMsg2 = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg2 = '获取验证码';
                                    this.deadline = 60;
                                    this.btnToken2Disable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg2 = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnToken2Disable = false;
                            Toast(res.msg);
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnToken2Disable = false;
                        Toast('网路异常请稍后重试');
                    });
                }else{
                    Toast('请输入正确的手机号');
                    this.btnToken2Disable = false;
                }
                return false
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
        }
    }
</script>

<style>
    body{
        max-width: 766px;
        margin: auto;
        background: linear-gradient( to right, #fafafc 0%, #e3e4e6 100%);
    }
    #act240224{
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
    #act240224 .act-container{
        position: relative;
    }
    #act240224 .act-container .bg-img{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    #act240224 .act-container .bg-img img{
        width: 100%;
    }
    #act240224 .title-box{
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        padding-top: 24px
    }
    #act240224 .title-box .act-logo{
        width: 64px;
    }
    #act240224 .title-box .act-title{
        margin-top: 16px;
        color: #424242;
    }
    #act240224 .alert-box{
        color: #424242;
        font-size: 14px;
        font-weight: 600;
    }
    #act240224 .border-container{
        padding: 0 16px;
        margin-top: 32px;
    }
    #act240224 .border-line{
        background-image:url('/images/act/20240224/act-bg-border.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;
        padding: 16px;
        box-sizing: border-box;
    }
    #act240224 .mini-content{
        font-size: 9px;
        color: rgba(188,168,144,0.8);
        line-height: 14px;
        letter-spacing: 1.2px;font-weight: normal;
        margin-top: 12px;
    }
    #act240224 .info-container{
        height: 395px;
        overflow: hidden;
    }

    @media screen and (max-width: 640px){
        /*兼容小手机*/
        #act240224 .info-container{
            height: 200px;
            overflow: hidden;
        }
    }

    @media screen and (max-width: 640px){
        /*兼容小手机*/
        #act240224 .info-container{
            height: 320px;
            overflow: hidden;
        }
    }
    #act240224 .info-box{
        height: 100%;
        overflow: scroll;
    }
    #act240224 .info-container .info-title{
        text-align: center;
    }
    #act240224 .info-container .info-content{
        text-align: left;
        line-height: 21px;
        padding-top: 8px;
        font-weight: normal;
    }
    #act240224 .show-info-box .btn-list{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin-top: 24px;
        padding: 0 20px;
    }
    #act240224 .show-info-box .btn-list .btn-item{
        display: inline;
        color: #fff;
        background: rgba(0, 0, 0, 0.2);
        border: 1px solid #8A8A8C;
        border-radius: 16px;
        padding: 8px 14px;
        font-size: 12px;
        padding-left: 41px;
        position: relative;
        line-height: 18px;
        font-weight: normal;
        letter-spacing: 0.75px;
    }
    #act240224 .show-info-box .btn-list .btn-submit{
        /*color: #BCA890;*/
        background: rgba(188,168,144,0.44);
        border: 1px solid #BCA890;
    }
    #act240224 .show-info-box .btn-icon{
        width: 18px;
        height: 18px;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    #act240224 .alert-box .box-title{
        color: #424242;
        font-weight: bold;
        font-size: 16px;
        padding: 16px 0;
    }
    #act240224 .alert-box .submit-container{
        padding: 0 47px;
        padding-bottom: 72px;
    }
    #act240224 .alert-box .form-input{
        border-bottom: 1px solid #D4D4D6;
        padding-bottom: 8px;
        color: #8A8A8C;
        font-size: 14px;
        line-height: 14px;
        position: relative;
        font-weight:  normal;
    }
    #act240224 .alert-box .form-input input{
        background: transparent;
        border: none;
        padding-top: 30px;
        padding-right: 10px;
        width: 100%;
    }
    #act240224 .alert-box .form-input .mobile-btn{
        color: #BCA890;
        font-size: 14px;
        position: absolute;
        right: -10px;
        bottom: 10px;
    }
    #act240224 .alert-box .btn-list{
        margin-top: 70px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        position: relative;
    }
    #act240224 .alert-box .van-button--info{
        background: transparent;
        border-radius: 16px;
        border: 1px solid #8A8A8C;
        color: #8A8A8C;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        font-weight: normal;
    }
    #act240224 .alert-box .gold-btn{
        background: #BCA890;
        width: 217px;
        border: 1px solid #BCA890;
        color: #fff;
    }

    #act240224 .login-box .nav-list{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        color: #BCA890;
        font-size: 14px;
        padding-top: 23px;
        font-weight: normal;
    }

    #act240224 .register-box .cancel-btn, #act240224 .foreget-box .cancel-btn{
        position: absolute;
        top: 0;
        left: -23px;
        width: 109px;
    }
    #act240224 .register-box .submit-btn, #act240224 .foreget-box .submit-btn{
        position: absolute;
        top: 0;
        right: -23px;
        width: 109px;
    }

    #act240224 .userTypeAlert{
        background: transparent;
    }

    #act240224 .chosseUserType-container{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        background: linear-gradient( 102deg, #FAFAFC 0%, #E3E4E6 100%);
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        overflow: hidden;
        box-shadow: 0px -4px 16px 0px rgba(0,0,0,0.2);
    }
    #act240224 .chosseUserType-container .x-icon{
        width: 12px;
        height: 12px;
        position: absolute;
        top: 16px;
        right: 16px;
    }
    #act240224 .chosseUserType-container .user-type-title{
        font-size: 16px;
    }
    #act240224 .chosseUserType-container .user-type-chosse{
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        text-align: center;
        padding: 30px 0;
    }
    #act240224 .chosseUserType-container .user-type-chosse .user-type-choose-item{
        width: 92px;
        border-radius: 15px;
        color: #fff;
        height: 34px;
        line-height: 34px;
        background: #BCA890;
    }
    #act240224 .hidden {
        display: none;
    }
</style>
