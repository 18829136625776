<template>
    <div class="author">
    </div>
</template>

<script>
    export default {
        name: 'author',
        data() {
            return {}
        },
        created: function () {
            //触发 授权跳页
            let code = this.$route.query.code;    //授权code
            let state = this.$route.query.state;  //携带参数
            let redirectUri = typeof this.$route.query.path != 'undefined' ? encodeURI(this.$wechat.baseUrl + decodeURI(this.$route.query.path)) : ''; //前往的页面
            if(this.$route.query.path == '/author') {
                console.log('我即是无限');
                return false;
            } //打断无尽循环
            if (!code) {
                //请求获取链接并且重定向到微信授权页面
                if(process.env.NODE_ENV == 'development'){
                    //测试数据
                    this.$store.commit('user/setOpenId', 'oBnHy5pm8-ZvBT_DkrALL6yVrDOc');
					this.$store.commit('user/setUserId', 0);
					this.$store.commit('user/setMobile', 0);
                    //特殊跳转页面
                    window.location.replace(redirectUri);
                } else if(process.env .NODE_ENV == 'production'){
                    let authUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+this.$wechat.appId+'&redirect_uri='+redirectUri+'&response_type=code&scope=snsapi_userinfo&state='+window.btoa(state)+'#wechat_redirect';

                    window.location.replace(authUrl);
                }
            } else {
                //请求后台获取用户信息并且存入，然后跳转到对应页面
                window.console.log('===========code===========' + code);
                this.$post('/user/getOpenid', {
                    code: code,
                }).then((res) => {
                    if(res.code == 1000){
                        //去除后if内跳转无法生效
                        let that = this;
                        let data =res.data;
                        let openId = data.openid;
                        let userId = data.userid;

                        //更新数据
                        that.$store.commit('user/setOpenId', openId);
                        that.$store.commit('user/setUserId', userId);
                        state =JSON.parse(window.atob(state));

                        window.location.replace(redirectUri);
                    }else{
                        alert(res.msg);
                    }
                }).catch(
                    (e) =>{
                        window.console.log(e);
                    }
                )
            }
        },
        methods: {}
    }
</script>

<style>
    .author {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 45px;
    }
</style>
