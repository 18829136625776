<template>
    <div id="act240224_order">
        <!--    页面主题-->
        <div class="act-container">
            <div class="bg-img">
                <img class="act-logo" src="/images/act/20240224/act-bg-car.png"/>
            </div>
            <div class="show-info-box alert-box border-container">
                <form class="submit-container" @submit.prevent="subOrderInfo">
                    <div class="info-container border-line">
                        <div class="top-address">
                            <div class="point" @click="useMap">
                                <img class="point-icon" src="/images/act/20240224/act-icon-point.png"/>
                                <span class="point-text">{{subFormObj.cityName}}</span>
                            </div>
                        </div>
                        <div class="form-input">
                            <div class="left-title">乘客信息</div>
                            <input placeholder="请输入姓名" v-model="subFormObj.name" maxlength="11" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <input placeholder="请输入手机号" v-model="subFormObj.mobile" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="form-input">
                            <div class="left-title">航班/车次</div>
                            <input placeholder="请输入" v-model="subFormObj.flight_num" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="form-input" v-if="type == 1">
                            <div class="left-title">上车点</div>
                            <input class="airport-input" placeholder="请选择机场" @click="alertchooseAirport"  onfocus="this.blur()" v-model="startPointInfo.key" @blur="clearTop"/>
                            <img class="airport-arrow-icon" src="/images/act/20240224/act-icon-arrow.png"/>
                        </div>
                        <div class="form-input" v-else>
                            <div class="left-title">上车点</div>
                            <input placeholder="请输入" @click="chooseStartPoint"  onfocus="this.blur()" v-model="startPointInfo.key" @blur="clearTop"/>
                        </div>
                        <div class="form-input" v-if="type == 2">
                            <div class="left-title">下车点</div>
                            <input class="airport-input" placeholder="请选择机场" @click="alertchooseAirport"  onfocus="this.blur()" v-model="endPointInfo.key" @blur="clearTop"/>
                            <img class="airport-arrow-icon" src="/images/act/20240224/act-icon-arrow.png"/>
                        </div>
                        <div class="form-input" v-else>
                            <div class="left-title">下车点</div>
                            <input placeholder="请输入" @click="chooseEndPoint"  onfocus="this.blur()" v-model="endPointInfo.key" @blur="clearTop"/>
                        </div>
                        <div class="form-input">
                            <div class="left-title">用车时间</div>
                            <div class="usetime-input" @click="timeCalendarShow = true">
                                {{subFormObj.date}} {{subFormObj.time}}
                                <img class="time-arrow-icon" src="/images/act/20240224/act-icon-arrow.png"/>
                            </div>
                        </div>
                        <div class="form-input">
                            <div class="left-title">活动码</div>
                            <input placeholder="请输入" v-model="subFormObj.token" @blur="clearTop" maxlength="30"/>
                        </div>
                        <div class="form-input">
                            <div class="left-title left-title2">备注</div>
                            <textarea placeholder="请输入" v-model="subFormObj.desc" @blur="clearTop">
                            </textarea>
                        </div>
                        <div class="form-check">
                            <van-checkbox shape="square" v-model="subFormObj.hasPhone">是否同意电话联系</van-checkbox>
                        </div>
                    </div>
                    <div class="btn-list">
                        <van-button class="submit-btn  gold-btn" round type="info" size="large" native-type="submit">
                            <span>下单</span>
                        </van-button>
                    </div>
                </form>
            </div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

        <van-overlay :show="mapShow"  class="overlay-div">
            <div class="map-container">
                <div class="map-box">
                    <TheMap class="map" @getLocation="getLocation" @selectLocation="selectLocation" @closeAlert="closeAlert"></TheMap>
                </div>
            </div>
        </van-overlay>

        <van-overlay :show="airPortChooseShow"  class="overlay-div">
            <div class="airport-container">
                <div class="airport-box">
                    <van-picker
                            title="选择机场"
                            show-toolbar
                            :columns="airporstList"
                            @confirm="onAirportConfirm"
                            @cancel="clsoeAirport"
                    />
                </div>
            </div>
        </van-overlay>

<!--        日历选择器-->
        <van-calendar v-model="timeCalendarShow"
                      :min-date="minDate"
                      :max-date="maxDate"
                      @confirm="onCalendarConfirm" />
<!--        时间选择器-->
        <van-overlay :show="timeChooseShow"  class="overlay-div">
            <van-datetime-picker
                    :min-hour="minHour"
                    cancel-button-text=" "
                    class="time-picker" type="time" :formatter="timeFormatter" :filter="timeFilter" @confirm="onTimeChooseConfirm" title="选择当日时间"/>
        </van-overlay>


        <div id='container'></div>
    </div>

</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast,Checkbox, Calendar, DatetimePicker } from 'vant';
    import BMap from 'BMap';
    import TheMap from '@/components/TheMap.vue'; //组件引用

    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(Checkbox).use(Calendar).use(DatetimePicker );
    export default {
        name: 'act240224_order',
        components:{
            TheMap
        },
        data() {
            return {
                address: '',
                loginShow: true,
                loadShow: false,
                subFormChecked : false,
                btnDisable : false,
                minDate: new Date(2024, 0, 1),
                maxDate: new Date(2024, 11, 31),
                minHour: 0,
                subFormObj: {
                    city : '',
                    cityName: '--',
                    name: '',
                    mobile: '',
                    date: '2024-02-20',
                    time: '19:30',
                    flight_num: '',
                    token: '',
                    desc: '',
                    hasPhone: true
                },
                startPointInfo: {
                    lng: '',
                    lat: '',
                    key: '',
                    province: ''
                },
                endPointInfo:{
                    lng: '',
                    lat: '',
                    key: '',
                    province: ''
                },
                mapShow: false,
                pointIndex: 0,//1 start ,2 end
                airPortChooseShow: false,
                type: 1, //1: 接机，2：送机
                airporstList: [
                    { text: '大兴机场', value: {'lat' : 39.50338, 'lng': 116.4198, 'province' : '北京'} },
                ],
                timeCalendarShow : false,
                timeChooseShow : false,
            }
        },
        created: function () {
            this.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            this.subFormObj.time = this.minDate.getHours()+':'+this.minDate.getMinutes();
            this.subFormObj.date = this.formatDate(this.minDate);

            let query = this.$route.query;
            this.type = query.type == 2 ? 2 : 1;
            let userId = this.$store.getters['user/getUserId'];
            if(userId == 0){
                window.location.replace('/audi_member');
            }

        },
        mounted(){
            this.createMap();
        },
        methods: {
            createMap() {
                var that = this;
                var map = new BMap.Map("container");
                var point = new BMap.Point(116.331398, 39.897445);
                map.centerAndZoom(point, 12);
                //直接用IP定位
                function myFun(result){
                    var cityName = result.name;
                    console.log("当前定位城市:"+cityName);
                    that.subFormObj.cityName = cityName;
                }
                var myCity = new BMap.LocalCity();
                myCity.get(myFun);
            },
            //使用地图
            useMap: function(){
                // console.log('==使用地图==');
            },
            //提交订单信息
            subOrderInfo: function (){
                var that = this;
                let data = this.subFormObj;
                let startPoint = this.startPointInfo;
                let endPoint = this.endPointInfo;

                if(data.name == ''){
                    Toast.fail('请填写乘客姓名');
                    return false;
                }else
                    if(data.mobile == ''){
                        Toast.fail('请填写乘客手机号');
                    return false;
                }else
                    if(data.flight_num == ''){
                        Toast.fail('请填写航班号');
                    return false;
                }else
                    if(startPoint.key == '' || startPoint.lat == '' || startPoint.lng == '' || startPoint.lat == null || startPoint.lng == null){
                        Toast.fail('请选择上车点');
                        return false;
                }else
                    if(endPoint.key == '' || endPoint.lat == '' || endPoint.lng == '' || endPoint.lat == null || endPoint.lng == null){
                        Toast.fail('请选择下车点');
                }else
                    if(endPoint.key == '' || endPoint.lat == '' || endPoint.lng == '' || endPoint.lat == null || endPoint.lng == null){
                        Toast.fail('请选择下车点');
                }else
                    if(data.token == ''){
                        Toast.fail('请填写折扣码');
                        return false;
                }

                this.loadShow = true;
                let subData = {
                    city : data.city,
                    appointment : data.date+' '+data.time,
                    code: data.token,
                    origin : startPoint.key,
                    end : endPoint.key,
                    passenger_name : data.name,
                    passenger_phone : data.mobile,
                    flight_number : data.flight_num,
                    remarks : data.desc,
                    tctype : data.hasPhone? 1 : 0,
                    uid : this.$store.getters['user/getUserId'],
                    openid : this.$store.getters['user/getOpenId'],
                    origins : this.startPointInfo.lat+','+this.startPointInfo.lng, //lat,lng origins
                    destinations : this.endPointInfo.lat+','+this.endPointInfo.lng //lat,lng end
                }

                this.$post('/user/audiSubmitOrder', subData).then((res) => {
                    if(res.code == 1000){
                        Toast(res.data.msg);
                        let orderId = res.data.orderid;
                        that.navToOrderDetail(orderId);
                    }else{
                        Toast.fail(res.msg)
                    }
                    this.loadShow = false;
                });
                return false;
            },
            //前往订单详情
            navToOrderDetail: function(orderId){
                this.$router.push({
                    path: 'audi_order_detail',
                    query: {
                        orderId : orderId //1: 接机，2：送机
                    },
                })
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            //地图回调
            getLocation(location) {
                //赋值经度纬度和地址
                if (location) {
                    this.form.lng = location.lng;
                    this.form.lat = location.lat;
                    this.form.projectAddress = location.address;
                }
            },
            //起始点
            chooseStartPoint: function(){
                this.pointIndex = 1;
                this.changeMapInfo(this.pointIndex);
            },
            //下车点
            chooseEndPoint: function(){
                this.pointIndex = 2;
                this.changeMapInfo(this.pointIndex);
            },
            //地图载入
            loadAirportInfo: function(){
                this.loadShow = true;
                this.$post('/user/getAirPort', []).then((res) => {
                    if(res.code == 1000){
                        let data = res.data;
                        let list = [];
                        if(data.length > 1){
                            for(var i=0; i < data.length;i++){
                                let item = {
                                    'text' :  data[i]['name'],
                                    'value' : data[i]
                                }

                                list.push(item);
                            }
                        }

                        this.airporstList = list;
                    }
                    this.loadShow = false;
                });
            },
            //选择机场
            alertchooseAirport: function(){
                // console.log('选择机场');
                this.loadAirportInfo();
                this.airPortChooseShow = true;
            },
            //关闭机场弹窗
            clsoeAirport: function(){
                this.airPortChooseShow = false;
            },
            // 选中机场信息
            onAirportConfirm: function(selectedValues){
                //1: 接机，2：送机
                this.subFormObj.city = selectedValues.value.province;
                this.subFormObj.cityName = selectedValues.value.province;
                if(this.type == 1){
                    this.startPointInfo = {
                        lng: selectedValues.value.lng,
                        lat: selectedValues.value.lat,
                        key: selectedValues.text,
                        province: selectedValues.value.province
                    }
                }else if(this.type == 2){
                    this.endPointInfo = {
                        lng: selectedValues.value.lng,
                        lat: selectedValues.value.lat,
                        key: selectedValues.text,
                        province: selectedValues.value.province
                    }
                }
                this.airPortChooseShow = false;
            },
            // 变更地图信息
            changeMapInfo: function(index){
                this.mapShow = true;
                if(index == 1){
                    var info = this.startPointInfo;
                }else if(index == 2){
                    var info = this.endPointInfo;
                }
                this.$emit('changeMapInfo', info);
            },
            // 选择地点
            selectLocation: function(res){
                if(this.pointIndex == 1){
                    //上车点
                    this.startPointInfo = res;
                    console.log('上车点:');
                }else if(this.pointIndex == 2){
                    //下车点
                    this.endPointInfo = res;
                    console.log('下车点:');
                }
                console.log(res);
                this.subFormObj.cityName = res.province;
                this.mapShow = false;
            },
            // 选择地点
            closeAlert: function(res){
                this.mapShow = false;
            },
            //日历选择
            onCalendarConfirm: function(res){
                let resTime = this.formatDate(res);
                this.subFormObj.date = resTime;

                let miniDate = this.formatDate(this.minDate);
                if(miniDate == resTime){
                    //时间为明天
                    let time = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
                    let hour = time.getHours();
                    this.minHour = hour;
                }else{
                    this.minHour = 0;
                }
                this.timeCalendarShow = false;
                this.timeChooseShow = true;
            },
            //时间选择
            onTimeChooseConfirm: function(res){
                this.subFormObj.time = res;
                this.timeChooseShow = false;
            },
            //时间区间5分钟
            timeFilter: function(type, options){
                if (type === 'minute') {
                    return options.filter((option) => Number(option) % 5 === 0);
                }
                return options;
            },
            //时间展示格式转换
            timeFormatter: function (type, val) {
                if (type === 'hour') {
                    return val + '时';
                }
                if (type === 'minute') {
                    return val + '分';
                }
                return val;
            },
            // 格式转换
            formatDate: function(date){
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            }
        }
    }
</script>

<style>
    body{
        max-width: 766px;
        margin: auto;
        background: linear-gradient( to right, #fafafc 0%, #e3e4e6 100%);
    }
    #act240224_order{
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
    #act240224_order .act-container{
        position: relative;
    }
    #act240224_order .act-container .bg-img{
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
    }
    #act240224_order .act-container .bg-img img{
        width: 100%;
    }
    #act240224_order .border-container{
        padding: 0 16px;
        margin-top: 32px;
    }
    #act240224_order .border-line{
        background-image:url('/images/act/20240224/act-bg-border.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% 100%;
        padding: 16px;
        box-sizing: border-box;
    }
    #act240224_order .top-address{
        position: relative;
        width: 100%;
        height: 16px;
    }
    #act240224_order .top-address .point{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        font-size: 12px;
        padding-left: 28px;
        height: 18px;
        line-height: 18px;
    }
    #act240224_order .top-address .point .point-icon{
        height: 16px;
        position: absolute;
        left: 10px;
    }
    #act240224_order .top-address .point .point-text{
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }
    #act240224_order .form-input{
        padding: 8px 0;
        padding-left: 86px;
        position: relative;
        font-size: 14px;
    }
    #act240224_order .form-input .left-title{
        text-align: right;
        color: #424242;
        position: absolute;
        display: block;
        width: 86px;
        height: 18px;
        line-height: 18px;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        padding-right: 16px;
    }
    #act240224_order .form-input .left-title2{
        top: 12px;
        bottom: auto;
    }
    #act240224_order .form-input input{
        width: 100%;
        background: transparent;
        border: 1px solid #D4D4D6;
        padding: 6px 8px;
    }
    #act240224_order .form-input .airport-input{
        border: none;
        padding-right: 20px;
    }
    #act240224_order .form-input .airport-arrow-icon{
        position: absolute;
        right: 4px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 10px;
    }
    #act240224_order .form-input textarea{
        width: 100%;
        background: transparent;
        border: 1px solid #D4D4D6;
        padding: 6px 8px;
    }
    #act240224_order .form-input .usetime-input{
        color: #8A8A8C;
        padding-left: 8px;
        font-size: 14px;
        text-align: left;
    }
    #act240224_order .form-input .time-arrow-icon{
        width: 16px;
        top: 0;
        bottom: 0;
        right: 4px;
        margin: auto;
        position: absolute;
        padding-left: 6px;
    }
    #act240224_order .form-check{
        padding: 8px 0;
        padding-left: 86px;
        position: relative;
        font-size: 14px;
        text-align: left;
    }
    #act240224_order .form-check .van-checkbox__icon{
        font-size: 12px;
    }
    #act240224_order .form-check .van-icon{
        border-color: #BCA890;
        background: transparent;
    }
    #act240224_order .form-check .van-checkbox__icon--checked .van-icon,#act240224_order .form-check .van-checkbox__label {
        color: #BCA890;
    }
    #act240224_order .gold-btn{
        border-radius: 16px;
        font-size: 12px;
        height: 32px;
        line-height: 32px;
        font-weight: normal;
        background: #BCA890;
        width: 217px;
        border: 1px solid #BCA890;
        color: #fff;
        margin-top: 37px;
    }

    #act240224_order .map-container{
        padding: 0 30px;
        position: relative;
        height: 100%;
        width: 100%;
    }
    #act240224_order .map-container .map-box{
        position: relative;
        height: 100%;
        width: 100%;
    }
    #act240224_order .map-container .map{
        position: absolute;
        width: 100%;
        z-index: 20;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    #act240224_order .airport-container{
        width: 100%;
        bottom: 0;
        position: fixed;
        background: #fff;
        padding-top: 10px;
        padding-bottom: 30px;
        border-radius: 15px 15px 0px 0px;
    }
    #act240224_order .airport-container .x-icon{
        position: absolute;
        right: 16px;
        top: 16px;
        width: 10px;
        height: 10px;
    }

    #act240224_order .time-picker{
        position: fixed;
        bottom: 0;
        width: 100%;
    }

    #act240224 .hidden {
        display: none;
    }
</style>
