import Vue from 'vue'
import App from './App.vue'
import './common/font/pf-m.css'
import './common/font/pf-b.css'
import wechat from './config/wechat'

import router from './router'
import store from './store/index';
import axios from 'axios'
import md5 from 'js-md5';
import {post, fetch, uploadFile} from "./config/axios";

Vue.prototype.$axios = axios;
Vue.prototype.$wechat = wechat;

Vue.prototype.$get = fetch;
Vue.prototype.$post = post;
Vue.prototype.$uploadFile = uploadFile;
Vue.prototype.$md5 = md5;

Vue.config.productionTip = false;


//获取用户id和token

router.beforeEach((to, form, next) => {
    let openId = store.getters['user/getOpenId'];

    if(to.path == '/act20221226'){
        next();
        return ;
    }

    //路由拦截用户判断用户是否登陆过
    if ((openId) || to.path == '/author') {
        next();
        return ;
        //拥有openId（说明已经授权过）
        if (to.path == '/author') {
            //其他的部分不处理
            next();
            return;
        }

        next();
        return;
    } else {
        //未拥有信息，并且不是授权页，强制跳转到授权页面，并且将请求封装
        if (to.query.code) {
            //有code的情况下，直取code，state和对应的页面值
            next({path: '/author', query: {code: to.query.code, state: to.query.state, path: encodeURI(to.path)}})
        } else {
            //无code的情况下，封装query请求为state和对应的页面值
            next({path: '/author', query: {state: JSON.stringify(to.query), path: encodeURI(to.path)}})
        }
        return;
    }
});

//后置钩子 用户配置wx配置
router.afterEach((to, from) => {
    if(to.name != 'author'){
        //如果是辅助登录页不访问这个，以下是需要wx配置的访问页面

        if(to.path != '/act20221226'){
            let url = wechat.baseUrl + to.fullPath;
            Vue.prototype.$post('/user/getWXConfig', {
                pathUrl  : url,
            }).then((res) => {
                if(res.code == 1000){
                    let data = res.data;
                    console.log(data.signature);
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: wechat.appId, // 必填，公众号的唯一标识
                        timestamp: data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: data.nonce_str, // 必填，生成签名的随机串
                        signature: data.signature,// 必填，签名
                        jsApiList: ['showMenuItems','hideAllNonBaseMenuItem', 'getLocation','updateAppMessageShareData', 'onMenuShareAppMessage', 'updateTimelineShareData', 'onMenuShareTimeline', 'chooseWXPay', 'closeWindow'], // 必填，需要使用的JS接口列表
                        openTagList: ['wx-open-launch-weapp']
                    });
                    wx.error(function(res){
                        console.log(res);
                        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
                    });
                }else{
                    alert(res.msg);
                }
            })
        }
    }
});

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
