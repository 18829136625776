<template>
    <div class="login">
        <!--    验证码主体-->
        <img class="banner" src="/images/banner0.jpg"/>
        <div class="container">
            <!-- 路由对应组件的显示 -->
            <div class="source">
                <div class="title">
                    <p>注：本公众号服务内容仅对协议企业员工开放，凭邀请码或扫描员工购车二维码进入</p>
                </div>
                <form class="container" @submit.prevent="submitCustCode">
                    <img class="title-img" src="/images/login-title1.png"/>
                    <img class="title-wh" src="/images/icon-wh.png" @click="infoShow=true"/>
                    <input class="submit-content" type="content" placeholder="请填写邀请码" v-model="tokenFormObj.token" @blur="clearTop"/>
                    <div class="warn-info">
                        <span v-if="warnLv == 1">请输入邀请码</span>
                        <span v-else-if="warnLv == 2">邀请码错误</span>
                    </div>
                    <div class="login-btn" @click="login()">
                        <p>已有账户，直接登陆</p>
                    </div>
                    <van-button class="submit-btn" round type="info" size="large" native-type="submit">确认</van-button>
                </form>
            </div>
        </div>
        <div clas="alert-container" >
            <!--    注册/登陆弹窗-->
            <van-overlay :show="logShow">
                <div class="wrapper" @click.stop>
                    <div class="log-block">
                        <div class="close-btn" @click="clickClose()" v-if="logType==1">x</div>
                        <div class="title">
                            <p v-if="logType == 1">绑定账号</p>
                            <p v-else>快捷注册</p>
                        </div>
                        <form class="submit-container" @submit.prevent="logForm">
                            <div class="form-input mobile-container">
                                <input placeholder="请输入手机号" v-model="logFormObj.mobile" maxlength="11" @blur="clearTop"/>
                                <img src="/images/lab_m.png">
                                <div class="mobile-btn" @click="getMobileToken()">{{tokenBtnMsg}}</div>
                            </div>
                            <div class="form-input pwd-container">
                                <input placeholder="请输入验证码" v-model="logFormObj.token" @blur="clearTop"/>
                                <img src="/images/lab_c.png">
                            </div>
                            <div class="warn-info">
                                <span v-if="alertWarnLv == 1">请输入正确的手机号</span>
                                <span v-else-if="alertWarnLv == 2">验证码错误</span>
                                <span v-else-if="alertWarnLv == 3">{{alertWarnContent}}</span>
                            </div>

                            <van-button class="submit-btn" round type="info" size="large" native-type="submit">
                                <span v-if="logType == 1">登陆</span>
                                <span v-else>确认</span>
                            </van-button>
                        </form>
                        <div class="jump-btn" v-if="logType==2">
                            <span @click="jumpLog()">跳过此步骤</span>
                        </div>
                    </div>
                </div>
            </van-overlay>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading} from 'vant';
    vue.use(Button).use(Overlay).use(Loading);
    export default {
        name: 'login',
        data() {
            return {
                userType: 0, //用户状态 0：未绑定企业 1：已绑定企业
                logShow: false,
                loadShow : false,
                logType: 1,  //绑定账号：1|注册账号：2
                warnLv : 0,  //提示等级 0:无提示 1：提示填入邀请码 2：提示填入正确的邀请码
                alertWarnLv: 0, //提示等级 0:无提示 1：请输入正确的手机号 2：验证码错误 3：其他
                alertWarnContent : '',
                logFormObj: {
                    mobile: '',
                    token: ''
                },
                tokenFormObj: {
                    token: ''
                },
                tokenBtnMsg : '获取验证码',
                deadline : 60,
                btnDisable : false,
                btnDisable2 : false
            }
        },
        created: function () {
            //重新获取用户信息状态
            this.$post('/getUserInfoByOpenId', {
                openId: this.$store.getters['user/getOpenId']
            }).then((res) => {
                if (res.code == 200) {
                    let data = res.data;
                    let custId = data.custId;
                    let userId = data.userId;

                    //更新数据
                    this.$store.commit('user/setUserId', userId);
                    this.$store.commit('user/setCustId', custId);

                    this.$data.loadShow = false;
                    if(custId == 0){
                        //判断是否无企业id-不动
                    }else if(userId == 0){
                        //判断是否无用户id-修改用户状态未已绑定企业
                        window.console.log(this.$store.getters['local/getType']);
                        this.$data.userType = 1;
                        this.$data.logType = 2;
                        this.$data.logShow = true;
                    }else{
                        //都有，那么请你去index主页候命
                        let urlType = this.$store.getters['local/getType'];
                        if(urlType == 3){
                            this.$router.push({path: "/personal"});
                        }else if(urlType == 2){
                            this.$router.push({path: "/sp-index"});
                        }else if(urlType == 9){
                            this.$router.push({path: this.$store.getters['local/getUrl']});
                        }else{
                            this.$router.push({path: "/index"});
                        }
                    }
                } else {
                    alert(res.msg);
                }
            })
        },
        methods: {
            /**
             * 弹窗绑定账号
             */
            login: function () {
                this.showTypeAlert(1);
            },
            /**
             * 提交邀请码并弹出注册账号
             * @returns {boolean}
             */
            submitCustCode : function (){
                let code = this.$data.tokenFormObj.token;
                if(code == ''){
                    this.$data.warnLv = 1;
                    return false
                }
                //请求后端判断邀请码是否正确
                this.$data.logShow = false;
                this.$post('/userBindCust', {
                    openId : this.$store.getters['user/getOpenId'],
                    code :  code,
                }).then((res) => {
                    if(res.code == 200){
                        this.$data.userType = 1;
                        this.$data.logType = 2;
                        this.$data.logShow = true;
                    }else{
                        this.$data.warnLv = 2;
                    }
                });
            },
            /**
             * 获取手机验证码
             */
            getMobileToken: function () {
                if(this.btnDisable){
                    return false;
                }
                this.btnDisable = true;
                let mobile = this.logFormObj.mobile;
                if(this.isPhone(mobile)){
                    this.alertWarnLv = 0;
                    this.$post('/userGetRand', {
                        openId : this.$store.getters['user/getOpenId'],
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //循环倒计时
                            this.tokenBtnMsg = this.deadline + 's';
                            this.int_btnClock = window.setInterval(() => {
                                if(this.deadline <= 0){
                                    window.clearInterval(this.int_btnClock);
                                    this.tokenBtnMsg = '获取验证码';
                                    this.deadline = 60;
                                    this.btnDisable = false;
                                    return;
                                }
                                this.deadline--;
                                this.tokenBtnMsg = this.deadline + 's';
                            },1000);
                        }else{
                            this.btnDisable = false;
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                        }
                    }).catch(e => {
                        window.console.log(e);
                        this.btnDisable = false;
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                    });
                }else{
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false
            },
            /**
             *  绑定注册/登陆
             * @returns {boolean}
             */
            logForm: function () {
                if(this.btnDisable2){
                    return false;
                }
                this.btnDisable2 = true;

                let url = '';
                if(this.logType == 1){
                    //继承绑定账号
                    url = '/inheritAccount';
                }else if(this.logType == 2){
                    //绑定手机
                    url = '/userBindMobile';
                }else{
                    return false;
                }
                //请求绑定
                let mobile = this.logFormObj.mobile;
                let code = this.logFormObj.token;

                if(this.isPhone(mobile)){
                    //判断验证码
                    if(code == '' || typeof code == 'undefined'){
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '请输入验证码';
                        this.btnDisable2 = false;
                        return false;
                    }

                    this.alertWarnLv = 0;
                    this.$post(url, {
                        openId : this.$store.getters['user/getOpenId'],
                        code : code,
                        mobile :  mobile,
                    }).then((res) => {
                        if(res.code == 200){
                            //都有，那么请你去index主页候命
                            let urlType = this.$store.getters['local/getType'];
                            window.console.log(urlType);
                            if(urlType == 3){
                                window.location.href = "/personal";
                            }else if(urlType == 2){
                                window.location.href = "/sp-index";
                            }else if(urlType == 9){
                                window.location.href = this.$store.getters['local/getUrl'];
                            }else{
                                window.location.href = "/index";
                            }
                        }else{
                            this.alertWarnLv = 3;
                            this.alertWarnContent = res.msg;
                            this.btnDisable2 = false;
                        }
                    }).catch(e => {
                        this.alertWarnLv = 3;
                        this.alertWarnContent = '网路异常请稍后重试';
                        this.btnDisable2 = false;
                    });
                }else{
                    this.alertWarnLv = 1;
                    this.btnDisable = false;
                }
                return false;
            },
            /**
             * 跳过该步骤(直接跳转到首页)
             */
            jumpLog: function () {
                this.$router.push("/index").catch(e => window.console.log(e));
            },
            /**
             * 根据需求弹出不同的状态登陆/注册窗口
             * @param type
             */
            showTypeAlert: function (type) {
                this.$data.logType = type;
                this.$data.logShow = true
            },
            /**
             * ，关闭弹窗
             */
            clickClose: function (){
                this.logShow=false;
                this.logFormObj={
                    mobile: '',
                    token: ''
                };
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            }
        }
    }
</script>

<style>
    .alert-container{
        display: flex;
    }
</style>
