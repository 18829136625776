<template>
    <div id="act221226">
        <!--    验证码主体-->
        <img class="bg-show" src="/images/act/20221226/Group 63.jpg"/>
        <div class="act-container">
            <div class="source">
                <img class="act-logo" src="/images/act/20221226/Frame.png"/>
                <div class="act-title">
                    欢迎使用威信出行预订服务
                </div>
                <form class="container" @submit.prevent="submitActInfo">
                   <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*姓名" v-model="tokenFormObj.name" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="number" placeholder="*手机号" v-model="tokenFormObj.phone" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*兑换号" v-model="tokenFormObj.code" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*城市" v-model="tokenFormObj.city" readonly @click="showCityPicker"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*用车起始地" v-model="tokenFormObj.origin" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*用车目的地" v-model="tokenFormObj.end" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*时间" v-model="tokenFormObj.use_time" readonly @click="showTimePicker" />
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="*航班号" v-model="tokenFormObj.flight_code" @blur="clearTop"/>
                    </div>
                    <div class="input-box">
                        <input class="submit-content" type="content" placeholder="备注" v-model="tokenFormObj.desc" @blur="clearTop"/>
                    </div>
                    <div class="info-box">
                        <van-checkbox v-model="subFormChecked" checked-color="#FF6D6D">同意使用<label @click="alertInfo2" style="color: #4c9cd3;">《服务使用条款》</label></van-checkbox>
                    </div>
                    <van-button class="submit-btn" :disabled="!subFormChecked" round type="info" size="large" native-type="submit">提交</van-button>
                </form>
            </div>
        </div>

        <van-overlay :show="loadShow"  class="overlay-div">
            <van-loading type="spinner"  class="load"/>
        </van-overlay>

        <!-- 城市选择-->
        <van-popup v-model="cityPicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-picker
                    title="请选择使用城市"
                    show-toolbar
                    :columns="columns"
                    value-key = "text"
                    @cancel="cityPicker = false"
                    @confirm="onLocalConfirm"
            ></van-picker>
        </van-popup>

        <!--时间选择-->
        <van-popup v-model="timePicker" position="bottom" :close-on-click-overlay="false" safe-area-inset-bottom>
            <van-datetime-picker
                    v-model="currentDate"
                    type="datetime"
                    title="选择用车时间"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onTimeConfirm"
                    @cancel="timePicker = false"
            />
        </van-popup>

        <!--    问号弹窗-->
        <van-overlay :show="infoShow">
            <div class="wrapper">
                <div class="info-block" @click.stop>
                    <section class="info-content">
                        <article>
                            <div class="title">尊敬的阁下： </div>
                            <p>您好！ </p>
                            <p>您的用车预定信息已提交成功。 我们会尽快处理并发送短信确认您的订单。</p>
                            <p class="p-info">如有任何问题需修改或取消订单 ，请致电我司24小时工作电话：021 -50703315。感谢阁下对威信汽车租 赁(上海)有限公司豪华车服务的支持 谢谢垂注！</p>
                        </article>
                        <footer>
                            <div class="close-btn" @click="infoShow=false">我知道了</div>
                        </footer>
                    </section>
                </div>
            </div>
        </van-overlay>
        <!--    问号弹窗2-->

        <van-overlay :show="infoShow2" :lock-scroll="false">
            <div class="wrapper">-36，m'n'm'na
                <div class="info-block">
                    <section class="info-content">
                        <article>
                            <div class="title-2">《服务使用条款》 </div>
                               <p>礼遇服务内容：机场到同城市区，或市区到同城机场，点对点单程接机或单程送机服务，国内专车免费接送。</p>
                           <p>本服务仅限奥迪贵宾客户本人申请，可转赠家人（含父母、配偶或子女）使用，预定服务时请使用奥迪客户兑换权益时的注册手机，如需转赠服务，请在兑换页面正确填写实际使用人信息。因服务车辆为奥迪轿车，建议搭乘人数为三名人员；行李数量不超过两件托运箱或三件登机箱。</p>
                            <p>如需使用该服务，请至少提前24小时进行预约；如遇国家法定节假日（周六、日除外）需提前3个工作日。如需变更或取消服务，需提前6小时进行申请，若未申请将视为贵宾客户已享受服务；如贵宾客户未按约定时间达到且未致电通知，致使司机等待超过2小时，司机将取消等待，且视贵宾客户已享受本次服务。</p>
                            <p>服务车型：奥迪A8，奥迪A6</p>
                            <p>服务费用：</p>
                            <p>基准费用：本服务下所涵盖的免收费用包括点对点行程内的车辆租金、司机费用、燃油费、机场至同行政区域市区间的高速公路费；</p>
                            <p>等候费：接机服务，航班落地后免费等候60分钟，送机自预约上车时间起算免费等候30分钟，超时需按80元/小时收取等候费用（含机场停车费）</p>
                            <p>计算方式：自客户约定上车时间起，即进入享受接（送）机服务状态。</p>
                            <p>联系电话：<a style="color:red" href="tel:021-50703315">021-50703315</a></p>
                        </article>  
                        <footer>
                            <div class="close-btn" @click="infoShow2 = false">我知道了</div>
                        </footer>
                    </section>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import vue from 'vue';
    import {Button, Overlay, Loading, Popup, Picker, Toast, DatetimePicker,Checkbox} from 'vant';
    vue.use(Button).use(Overlay).use(Loading).use(Popup).use(Picker).use(Toast).use(DatetimePicker).use(Checkbox);
    export default {
        name: 'act221226',
        data() {
            return {
                cityPicker: false,
                columns: [
                    {'text' : '北京'},
                    {'text' : '上海'},
                    {'text' : '广州'},
                    {'text' : '深圳'},
                    {'text' : '杭州'},
                    {'text' : '成都'},
                    {'text' : '南京'},
                    {'text' : '武汉'},
                    {'text' : '长沙'},
                    {'text' : '西安'},
                ],
                timePicker: false,
                currentDate: new Date(2023, 0, 1),
                minDate: new Date(2023, 0, 1),
                maxDate: new Date(2023, 5, 30),
                infoShow: false,
                infoShow2: false,
                loadShow : false,
                tokenFormObj: {
                    name: '',
                    phone: '',
                    code: '',
                    city: '',
                    use_time: '',
                    flight_code: '',
                    desc: '',
                    origin : '',
                    end : ''
                },
                subFormChecked : false,
                btnDisable : false
            }
        },
        created: function () {
            this.currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            this.minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
        },
        methods: {
            /**
             * 提交活动
             * @returns {boolean}
             */
            submitActInfo : function (){
                if(this.$data.btnDisable) return false;
                let name = this.$data.tokenFormObj.name;
                if(name == ''){
                    Toast.fail('请输入姓名');
                    return false
                }
                let phone = this.$data.tokenFormObj.phone;
                if(phone == '' && !this.isPhone(phone)){
                    Toast.fail('请输入正确的手机号');
                    return false
                }
                let code = this.$data.tokenFormObj.code;
                if(code == ''){
                    Toast.fail('请输入兑换码');
                    return false
                }
                let city = this.$data.tokenFormObj.city;
                if(city == ''){
                    Toast.fail('请选择城市');
                    return false
                }
                let origin = this.$data.tokenFormObj.origin;
                if(origin == ''){
                    Toast.fail('请输入用车起始地');
                    return false
                }
                let end = this.$data.tokenFormObj.end;
                if(end == ''){
                    Toast.fail('请输入用车目的地');
                    return false
                }
                let use_time = this.$data.tokenFormObj.use_time;
                if(use_time == ''){
                    Toast.fail('请选择使用时间');
                    return false
                }
                let flight_code = this.$data.tokenFormObj.flight_code;
                if(flight_code == ''){
                    Toast.fail('请输入航班号');
                    return false
                }
                let formObj = this.$data.tokenFormObj;
                this.$data.loadShow = true;
                this.$data.btnDisable = true;

                this.$post('/submitActOrderAudi2022', {
                    name: formObj.name,
                    phone: formObj.phone,
                    code: formObj.code,
                    city: formObj.city,
                    use_time: formObj.use_time,
                    flight_code: formObj.flight_code,
                    desc: formObj.desc,
                    origin: formObj.origin,
                    end: formObj.end,
                }).then((res) => {
                    if(res.code == 1000){
                        this.$data.infoShow = true;
                    }else{
                        Toast.fail(res.info);
                    }
                    this.$data.btnDisable = false;
                    this.$data.loadShow = false;
                }).catch((e) =>{
                    this.$data.btnDisable = false;
                    this.$data.loadShow = false;
                });
            },
            // 检查字符串是否为合法手机号码
            isPhone: function (str) {
                let reg = /^(1[3-9][0-9])[0-9]{8}$/;
                if (reg.test(str)) {
                    // console.log('手机号码格式输入正确');
                    return true;
                } else {
                    // console.log('请输入正确格式的手机号码');
                    return false;
                }
            },
            // 清空
            clearTop: function(){
                setTimeout(function(){
                    var scrollHeight = document.documentElement.scrollTop || document.body.scrollTop || 0;
                    window.scrollTo(0, Math.max(scrollHeight - 1, 0));
                }, 100);
            },
            /**
             * 展示城市选择
             */
            showCityPicker(){
                this.$data.cityPicker = true;
            },
            /**
             * 地址选择确认
             */
            onLocalConfirm(value){
                this.$data.cityPicker = false;
                this.$data.tokenFormObj.city = value.text;
            },
            /**
             * 展示时间选择
             */
            showTimePicker(){
                this.$data.timePicker = true;
            },
            /**
             * 选择时间
             */
            onTimeConfirm(value){
                let date = new Date(value);
                let m = date.getMinutes();
                if(m<10){m='0'+m}
                let strTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + m;
                this.$data.tokenFormObj.use_time = strTime;
                this.$data.timePicker = false;
            },
            /**
             * 弹窗
             */
            alertInfo2(){
                this.$data.infoShow2 = true;
            }
        }
    }
</script>

<style>
    body{
        max-width: 766px;
        margin: auto;
        background: #00020e;
    }
    #act221226{
        position: relative;
        height: 100%;
        width: 100%;
        overflow-y: hidden;
    }
    #act221226 .bg-show{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }
    #act221226 .act-container{
        overflow: scroll;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 48px 44px ;
        box-sizing: border-box;
    }
    #act221226 .act-container .act-logo{
        width: 82px;
        height: 86px;
        padding-bottom: 8px;
    }
    #act221226 .act-container .act-title{
        /*font-weight: bold;*/
        color: #fff;
        font-size: 24px;
        padding-bottom: 24px;
        letter-spacing: 2px;
        white-space: nowrap;
    }
    #act221226 .act-container .container{
        padding: 0 14px;
    }
    #act221226 .act-container .input-box{
        box-sizing: border-box;
        width: 100%;
    }
    #act221226 .act-container input{
        background: rgba(0, 0, 0, 0.4);
        border: 1px solid #fff;
        height: 38px;
        line-height: 38px;
        margin-top: 14px;
        padding: 0 10px;
        width: 100%;
        box-sizing: border-box;
        font-size: 12px;
        color: #bcbdbd;
    }
    #act221226 .act-container input::-webkit-input-placeholder{
        color: #bcbdbd;
        letter-spacing: 1.5px;
    }
    #act221226 .act-container input::-ms-input-placeholder{
        color: #bcbdbd;
        letter-spacing: 1.5px;
     }
    #act221226 .act-container .submit-btn{
        background: #fff;
        width: 120px;
        color: #333;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        margin-top: 38px;
        border: none;
    }
    #act221226 .act-container .info-box{
        margin-top: 16px;
    }
    #act221226 .act-container .info-box .van-checkbox__label{
        color: #bcbdbd;
        font-size: 8px;
    }
    #act221226 .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        padding: 0 30px;
    }
    #act221226 .wrapper .info-block {
        width: 100%;
        position: relative;
        background: #fff;
        text-align: left;
        padding: 14px 20px;
        border-radius: 10px;
        color: #000;
        font-size: 14px;
        font-weight: bold;
        max-height: 420px;
        overflow: scroll;
    }
    #act221226 .wrapper .info-block .title{
        line-height: 24px;
    }
    #act221226 .wrapper .info-block .title-2{
        text-align: center;
        line-height: 24px;
    }
    #act221226 .wrapper .info-block p{
        text-indent: 2em;
        line-height: 24px;
    }
    #act221226 .wrapper .info-block .p-info{
        margin-top: 30px;
    }
    #act221226 .wrapper .close-btn{
        font-size: 12px;
        border-radius: 6px;
        background: #D9D9D9;
        width: 128px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        margin: auto;
        left: 0;
        right: 0;
        margin-top: 22px;
        font-weight: normal;
    }

    #act221226 .overlay-div{
        position: fixed;
    }
    #act221226 .overlay-div .load{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    #act221226 .overlay-div .load .van-loading__spinner{
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    #act221226 .van-popup--bottom{
        max-width: 766px    ;
        margin: auto;
        left: 0;
        right: 0;
    }
</style>
