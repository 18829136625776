import Vue from 'vue'
import Router from 'vue-router'

import login from '@/pages/index/login.vue'
import author from '@/pages/index/author.vue'
import act20221226 from '@/pages/act/act20221226.vue'
import audi_activities from '@/pages/act/act20240224.vue'
import audi_history from '@/pages/act/act20240224-hisotry.vue'
import audi_order from '@/pages/act/act20240224-order.vue'
import audi_order_detail from '@/pages/act/act20240224-order-detail.vue'

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: login,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/author',
            name: 'author',
            component: author,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/act20221226',
            name: 'act20221226',
            component: act20221226,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/audi_member',
            name: 'audi_activities',
            component: audi_activities,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/audi_history',
            name: 'audi_history',
            component: audi_history,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/audi_order',
            name: 'audi_order',
            component: audi_order,
            children: [
                {path: '*'}
            ],
        },
        {
            path: '/audi_order_detail',
            name: 'audi_order_detail',
            component: audi_order_detail,
            children: [
                {path: '*'}
            ],
        },
        {path: '*', redirect: '/act20221226'}
    ],
    mode: "history",
    base: process.env.VUE_APP_SRC,
})