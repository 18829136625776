import {baseUrl, imgUrl} from "./env"
import axios from 'axios';
import store from "../store";
import md5 from 'js-md5';

axios.default.timeout = 10000;//设置请求时间
axios.defaults.baseURL = baseUrl;//设置默认接口地址
axios.defaults.withCredentials = true;

/**
 * 封装get方法
 */
export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        this.$axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

/**
 * 封装post方法
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        this.$axios.post(url, data)
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
    })
}

/**
 * 封装图片上传方法
 * @param url
 * @param data 请求内容
 * @param file 图片文件
 * @returns {Promise<any>}
 */
export function uploadFile(url, data = {}, file){
    return new Promise((resolve, reject) => {
        //添加图片到form对象
        let form = new FormData();
        form.append('file', file, file.name);
        //其他数据
        form.append('params', JSON.stringify(data));
        // window.console.log(form.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
        };  //添加请求头
        this.$axios.post(url, form, config)
            .then(response => {
                response.data['baseUrl'] = imgUrl;
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}
