let baseUrl = '';
let imgUrl = 'https://mapi.parklanelimo.com.cn/';

if(process.env.NODE_ENV == 'development'){
    baseUrl = '/api';
} else if(process.env .NODE_ENV == 'production'){
    baseUrl = '/api/'; //nginx内配置跨域
    // baseUrl = '生产地址';
}

export {
    baseUrl,
    imgUrl
}